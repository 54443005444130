export const environment = {
  COMPANY_NAME: "evollo",
  APP_NAME: "ID",
  production: true,
  apiUrl: "https://saas-account.api.evollo.cloud",
  qualityApiUrl: "https://saas-quality.api.evollo.cloud",
  recaptchaId: "6Le_4tQmAAAAALwMgkZF8dyNpcwY9KG1-a8_se6m",
  webClientId: 'aq4mp7iv6fpn3jddupmahr5k4',
  apps: {
    id: "https://eva.id.evollo.com.br",
    admin: "https://eva.admin.evollo.com.br",
    quality: "https://eva.insighthub.evollo.com.br",
    cognito: "id-saas.auth.us-east-1.amazoncognito.com"
  },
  features: {
		enableSignUp: false,
		enableAzureSignUp: true,
		recaptchaEnabled: true,
		enableSupport: false,
		enableFaq: false
	}
};
